<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
import Constantes from '@/Constantes';



export default {
    name: 'IndexDetalles',
    data() {
        return {
            
        }
    },
    mounted(){
        this.constante()
    },
    methods:{
        constante(){
            console.log(Constantes.URL_SERVIDOR);
        }
    }
}
</script>