import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import router from "./router";
import "@mdi/font/css/materialdesignicons.css";
import "font-awesome/css/font-awesome.css";
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import {  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add();
Vue.component('vue-fontawesome', FontAwesomeIcon);

// ...

Vue.use(Buefy, {
  defaultIconPack: 'fa'
});

Vue.config.productionTip = false;


new Vue({
  router: router,
  render: (h) => h(App),
}).$mount("#app");