import Vue from "vue";
import Router from "vue-router";
import App from "@/components/detalles/Index";
import EventBus from "@/EventBus";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/:id",
      name: "Index",
      props: true,
      component: App,
    }
  ],
});
export default router;
