<template>
    <div>
        <div class=" is-flex is-flex-direction-column is-align-items-center" style="background-color: rgb(0, 112, 171);">
  <div>
        <h1 class="title is-4 p-1 m-4 has-text-white">{{titulo}}</h1>   
  </div>
</div>
    </div>
</template>
<script>
export default {
    props: ['titulo'],
    data() {
        return {
            data: ""
        }
    },
}
</script>