<template>
    <div>
        <Header :titulo="data.titulo" class="p-0"></Header>
        <div class="p-4 pt-0">
            <div class="columns">
                <div class="column is-two-fifths p-1 mr-4">
                    <div class="rows">
                        <div class="row mb-3">
                            <div @click="abrir(data.detalles, data.titulo)" class="box"
                                style="width: 100%; align-content: center; align-items: center; display: flex; flex-direction: column;">
                                <div class="is-flex is-justify-content-center">
                                    <p>Da click en la Imagen para descargar promocional</p>
                                </div>
                                <b-image v-if="server != null && data.length !== 0" style="width: 32vw"
                                    :src="server + data.detalles"></b-image>
                            </div>
                        </div>
                        <div class="row" v-if="data.multimedia != null && data.pdf != null">
                            <div class="rows">
                                <div class="row pt-1">
                                    <div class="box">
                                        <div>
                                            <h1 class="title is-size-5 p-4 pb-0">Contactanos</h1>
                                        </div>
                                        <b-message size="is-small" v-if="openMessageResp" auto-close :duration="5000"
                                            :type="typeResponse">
                                            {{ responseMessage }}
                                        </b-message>
                                        <div class="section pt-4 pb-4">
                                            <b-field label="Nombre Completo">
                                                <b-input ref="name" v-model="name" expanded required />
                                            </b-field>
                                            <b-field label="Agencia">
                                                <b-input ref="agencia" v-model="agencia" :disabled="AgenciaDis" expanded
                                                    required />
                                            </b-field>
                                            <b-field label="Correo">
                                                <b-input type="email" ref="mail" v-model="correo" expanded required />
                                            </b-field>
                                            <b-field label="Mensaje">
                                                <b-input ref="message" type="textarea" v-model="message" required></b-input>
                                            </b-field>
                                            <div class="column is-flex is-justify-content-end">
                                                <b-button :loading="loading" type="is-info" @click="enviar()"
                                                    outlined>Enviar</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column p-1 m-0" v-if="data.multimedia != null && data.pdf != null">
                    <div class="box" style="display: flex; flex-direction: column; align-items: center; height: 100%;">
                        <div class="columns p-0">
                            <div class="column"
                                style="width: 100%; display: flex; flex-direction: column; align-items: center;">
                                <div class="columns p-0">
                                    <div class="column is-flex is-justify-content-center is-align-items-center">
                                        <b-button icon-left="arrow-left" size="is-small" @click="pages--"
                                            :disabled="pages <= 1"></b-button>
                                        <b-tag class="p-4">{{ pages }} / {{ numpages }}</b-tag>
                                        <b-button icon-right="arrow-right" size="is-small" @click="pages++"
                                            :disabled="pages == numpages"></b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-flex is-justify-content-center is-align-items-end"
                                style="position: absolute; right: 0;">
                                <b-button @click="abrir(data.multimedia)" type="is-info" icon-left="file-word-o" outlined>
                                    Itinerario
                                </b-button>
                            </div>
                        </div>

                        <pdf v-if="server != null && data.length !== 0" :src="server + data.pdf" :page="pages">
                            <template slot="loading">
                                <div class="section is-flex is-justify-content-center">
                                    loading content here...
                                </div>
                            </template>
                        </pdf>
                    </div>
                </div>
                <div class="column" v-if="data.multimedia == null && data.pdf == null">
                    <div class="rows" style="height: 100%;">
                        <div class="row" style="height: 100%;">
                            <div class="box" style="height: 100%;">
                                <div>
                                    <h1 class="title is-size-5 p-4 pb-0">Contactanos</h1>
                                </div>
                                <b-message size="is-small" v-if="openMessageResp" auto-close :duration="5000"
                                    :type="typeResponse">
                                    {{ responseMessage }}
                                </b-message>
                                <div class="section pt-4 pb-4">
                                    <b-field label="Nombre Completo">
                                        <b-input ref="name" v-model="name" expanded required />
                                    </b-field>
                                    <b-field label="Agencia">
                                        <b-input ref="agencia" v-model="agencia" :disabled="AgenciaDis" expanded required />
                                    </b-field>
                                    <b-field label="Correo">
                                        <b-input type="email" ref="mail" v-model="correo" expanded required />
                                    </b-field>
                                    <b-field label="Mensaje">
                                        <b-input ref="message" type="textarea" v-model="message" required></b-input>
                                    </b-field>
                                    <div class="column is-flex is-justify-content-end">
                                        <b-button :loading="loading" type="is-info" @click="enviar()"
                                            outlined>Enviar</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Inicio></Inicio>
    </div>
</template>
<script>
import pdf from "pdfvuer";
import 'pdfjs-dist/build/pdf.worker'
import DetalleService from "@/services/DetalleService"
import Header from "@/components/detalles/Header"
import MainService from "@/services/MainServices"
import EventBus from "@/EventBus"
import MailService from "@/services/MailService"
import Inicio from "@/components/Inicio.vue"
import Constantes from "@/Constantes";

export default {
    name: 'IndexDetalles',
    components: {
        Header,
        pdf,
        Inicio
    },
    data() {
        return {
            id: this.$route.params.id,
            data: [],
            server: null,
            pages: 1,
            numpages: 0,
            isOpen: false,
            user: null,
            name: null,
            agencia: null,
            cveAgencia: null,
            message: '',
            loading: false,
            responseMessage: '',
            openMessageResp: false,
            typeResponse: '',
            AgenciaDis: true,
            correo: null

        }
    },
    mounted() {
        this.obtenerData()
        this.obtenerServidorbackend()
        this.obtenerAgencia()

    },
    methods: {
        async enviar() {
            const fields = ["name", "agencia", "message", "mail"];

            for (const field of fields) {
                if (!this.$refs[field].checkHtml5Validity()) {
                    return;
                }
            }
            this.loading = true
            this.openMessageResp = false
            const respuesta = await MailService.sendMailInfOferta(this.name, this.agencia, this.id, this.message, this.correo)
            if (respuesta === true) {
                this.loading = false
                this.responseMessage = "Tu solicitud a sido enviada con exito, en breve no estaremos comunicando contigo!"
                this.openMessageResp = true
                this.typeResponse = 'is-success'
                this.isOpen = false
                this.name = ''
                this.message = ''
            } else {
                this.loading = false
                this.responseMessage = respuesta + " Porfavor comunicarse con el Area de IT."
                this.openMessageResp = true
                this.typeResponse = 'is-danger'
            }


        },
        abrir(url) {
            window.open(Constantes.URL_SERVIDOR + "/descargar.php?url=" + url, '_blank');
        },
        async obtenerData() {
            this.data = await DetalleService.obtenerData(this.id)
            this.numpages = await this.obtenerNumPaginas()
        },
        async obtenerNumPaginas() {
            try {
                const respuesta = await pdf.createLoadingTask(this.server + this.data.pdf)
                return respuesta._pdfInfo.numPages
            } catch (error) {
                const respuesta = await pdf.createLoadingTask(this.server + this.data.pdf)
                return respuesta._pdfInfo.numPages
            }

        },
        async obtenerServidorbackend() {
            this.server = await MainService.obtenerServer()
        },
        async obtenerAgencia() {
            const respuesta = EventBus.obtenerUsuario()
            this.agencia = respuesta.nameAgency
            this.cveAgencia = respuesta.reference
            if (this.agencia == undefined) {
                this.AgenciaDis = false
                this.agencia = null
            }
        }
    }
}
</script>
<style>
#btn-promocional {
    color: rgb(238, 125, 25);
    border-color: rgb(238, 125, 25);
}

#btn-promocional:hover {
    color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    background-color: rgb(238, 125, 25);
}</style>